import * as React from 'react';
import * as classnames from 'classnames/bind';
import {Images} from '../images/Images';
import {MenuItem, TopMenu} from './TopMenu';

const styles = require('./PageHeader.less');
const cx = classnames.bind(styles) as any;

export class PageHeader extends React.Component<any, any>
{
    render()
    {
        return <div className={'container pageHeader'} style={{color:'black'}}>
            <div className={'row'} style={{marginTop:'10px'}}>
                <div className={'col-sm-2'}>
                    <a href={'/'}>
                        <img style={{marginLeft:'0px', cursor:'default'}} height={'25px'} src={Images.ErgalioLogoCyan}/>
                    </a>
                </div>
                <div className={'col-sm-10'} style={{display:'flex', justifyContent:'flex-end'}}>
                                     <TopMenu menuItems={
                                         [   new MenuItem('Home','/'),
                                             new MenuItem('Services','/services'),
                                             new MenuItem('Careers','/careers'),
                                             new MenuItem('Company','/company')
                                         ]}/>

                </div>
            </div>
        </div>;
    }
}